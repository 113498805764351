import React from 'react';
import { ContactForm, Layout } from '../components';
import config from '../../config';

export default () => (
  <Layout>
    <ContactForm
      mailto={config.contactInfo.mailto}
      phoneNumberUrl={config.contactInfo.phoneNumberUrl}
      phoneNumberText={config.contactInfo.phoneNumberText}
    />
  </Layout>
);
